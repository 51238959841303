import React from 'react'

export default function Homepage() {
  return (
    <>
    <div><img src="https://i.imgur.com/TBAs6f9.png" alt="koushik murali signature" id='signature'></img></div>
    <div className="intro-copy">
      Hi, I am Koushik Murali. <br/> I am a Graphic Designer based out of Coimbatore, Tamil Nadu, India.
      <br/><br/>I like working on interesting projects that challenge my thinking and creativity. I try to be useful to other human beings. Sometimes, I like to write and publish my thoughts. During the weekend you can find me on my bike gasping for air.
      <br/><br/>But most of the time, I am in awe of the sheer ridiculousness that is my existence.
    </div>
    </>
  )
}
