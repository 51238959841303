import React from 'react'
import { drawingimages } from './drawingimages.js'
import { Carousel } from '@trendyol-js/react-carousel';

export default function Drawings() {
  console.log(drawingimages)
  return (
      <>

      <div className='gallery-container'>
      {drawingimages.map((results)=>
        {
          return (<img src={results.source} />)
        }
        )}
      </div>
    
      </>
  )
}
