import Homepage from './Homepage';
import NavBar from './NavBar';
import Footer from './Footer';
import Articles from './Articles';
import Drawings from './Drawings';
import Cancellation from './Cancellation';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



function App() {
  return (
    <div className='AppContainer'>
    <Router>
      <NavBar/>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/drawings" element={<Drawings />} />
        <Route path="/cancellation" element={<Cancellation />} />
      </Routes>
      <Footer/>
    </Router>
    </div>
  );
}

export default App;
