import React from 'react'
import { Link } from 'react-router-dom'

export default function NavBar() {
  return (
    <nav className='InterFont NavBar'>
       <div><Link to='/'>Home</Link></div>
        <div><Link to='/articles'>Articles</Link></div>
        <div><Link to='/drawings'>Drawings</Link></div>
    </nav>
  )
}
