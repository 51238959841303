import React from 'react'
export default function Cancellation() {
  return (
    <div>
      <div className='InterFont'>Full refund will be provided incase I am not able to complete the said goal. Contact me at +91 73730 74962</div>
    </div>
    
    
  )
}
