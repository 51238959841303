export const drawingimages = [
    {
        id: "1",
        title: "",
        source: "https://i.imgur.com/nVlut4K.jpg",
        description: "",
        artist: "",
    },
    {
        id: "2",
        title: "",
        source: "https://i.imgur.com/N9mLSOo.jpg",
        description: "",
        artist: "",
    },
    {
        id: "3",
        title: "",
        source: "https://i.imgur.com/UYy2ifR.jpg",
        description: "",
        artist: "",
    },
    {
        id: "4",
        title: "",
        source: "https://i.imgur.com/HTZmaVv.jpg",
        description: "",
        artist: "",
    },
    {
        id: "5",
        title: "",
        source: "https://i.imgur.com/nQt4o9O.jpg",
        description: "",
        artist: "",
    },
    {
        id: "6",
        title: "",
        source: "https://i.imgur.com/u5uAr5B.jpg",
        description: "",
        artist: "",
    },
    {
        id: "7",
        title: "",
        source: "https://i.imgur.com/hVwj7id.jpg",
        description: "",
        artist: "",
    },
    {
        id: "8",
        title: "",
        source: "https://i.imgur.com/1FObzRk.jpg",
        description: "",
        artist: "",
    },
    {
        id: "9",
        title: "",
        source: "https://i.imgur.com/pYT0a64.jpg",
        description: "",
        artist: "",
    },
    {
        id: "10",
        title: "",
        source: "https://i.imgur.com/x6En7Mr.jpg",
        description: "",
        artist: "",
    },
    {
        id: "12",
        title: "",
        source: "https://i.imgur.com/auYuXeZ.jpg",
        description: "",
        artist: "",
    },
    {
        id: "13",
        title: "",
        source: "https://i.imgur.com/hGkBQmI.jpg",
        description: "",
        artist: "",
    },
    {
        id: "14",
        title: "",
        source: "https://i.imgur.com/SfcD0D8.jpg",
        description: "",
        artist: "",
    },
    {
        id: "15",
        title: "",
        source: "https://i.imgur.com/iIusi6z.jpg",
        description: "",
        artist: "",
    },
    {
        id: "16",
        title: "",
        source: "https://i.imgur.com/WxdZZ6b.jpg",
        description: "",
        artist: "",
    },
    {
        id: "17",
        title: "",
        source: "https://i.imgur.com/9ef7mNj.jpg",
        description: "",
        artist: "",
    },
    {
        id: "18",
        title: "",
        source: "https://i.imgur.com/Imz5plD.jpg",
        description: "",
        artist: "",
    },
    {
        id: "19",
        title: "",
        source: "https://i.imgur.com/bUHbxxh.jpg",
        description: "",
        artist: "",
    },
    {
        id: "20",
        title: "",
        source: "https://i.imgur.com/6NhZ4pt.jpg",
        description: "",
        artist: "",
    },
    {
        id: "21",
        title: "",
        source: "https://i.imgur.com/dx8bSTk.jpg",
        description: "",
        artist: "",
    },
    {
        id: "22",
        title: "",
        source: "https://i.imgur.com/2Mv9J8R.jpg",
        description: "",
        artist: "",
    },
    {
        id: "23",
        title: "",
        source: "https://i.imgur.com/8d72HGZ.jpg",
        description: "",
        artist: "",
    },
    {
        id: "24",
        title: "",
        source: "https://i.imgur.com/XwGqG8F.jpg",
        description: "",
        artist: "",
    },
    {
        id: "25",
        title: "",
        source: "https://i.imgur.com/QwYy3kz.jpg",
        description: "",
        artist: "",
    },
    {
        id: "26",
        title: "",
        source: "https://i.imgur.com/xxvqjhn.jpg",
        description: "",
        artist: "",
    },
    {
        id: "27",
        title: "",
        source: "https://i.imgur.com/rDrQQUp.jpg",
        description: "",
        artist: "",
    },
    {
        id: "28",
        title: "",
        source: "https://i.imgur.com/z32aMu7.jpg",
        description: "",
        artist: "",
    },
    {
        id: "29",
        title: "",
        source: "https://i.imgur.com/jaUPN3T.jpg",
        description: "",
        artist: "",
    },
    {
        id: "30",
        title: "",
        source: "https://i.imgur.com/LSIPg9s.jpg",
        description: "",
        artist: "",
    },
    {
        id: "31",
        title: "",
        source: "https://i.imgur.com/ajruYX8.jpg",
        description: "",
        artist: "",
    },
    {
        id: "32",
        title: "",
        source: "https://i.imgur.com/FbpydLa.jpg",
        description: "",
        artist: "",
    },
]